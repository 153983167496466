import Rails from '@rails/ujs';
import {
  handleRecaptchaLoaded,
  handleRecaptchaSuccess,
  setupFormAutosubmit,
  setupFormValidation,
  setupSignupSelect,
} from 'utils/forms';
import { onDOMReady } from 'utils/dom';
import { setupFormSubmtAnalytics } from 'utils/analytics';

import 'bootstrap/js/dist/button';

Rails.start();

window.sessionlab = {
  ...window.sessionlab,
  handleRecaptchaSuccess,
  handleRecaptchaLoaded,
};

onDOMReady(setupFormValidation);
onDOMReady(setupFormSubmtAnalytics);
onDOMReady(setupFormAutosubmit);
onDOMReady(setupSignupSelect);
