import { getSiblings } from 'utils/dom';
import Rails from '@rails/ujs';

let recaptchaLoaded = false;

export const showFieldValidationError = (field, form) => {
  const errorMessageElement = getSiblings(field, '.invalid-feedback')[0];
  if (!errorMessageElement) return;
  if (field.validity.valid) {
    // We remove class set when there's a server side error
    field.classList.remove('is-invalid');
    // Not supported in IE, but minor feature so we just don't have it in IE either
    if (field.labels) {
      field.labels.forEach(label => label.classList.remove('is-invalid'));
    }
    errorMessageElement.innerHTML = '';
  } else {
    let fieldName = '';
    if (field.labels && form.classList.contains('was-validated')) {
      field.labels.forEach(label => {
        label.classList.add('is-invalid');
        fieldName = `${label.textContent} `;
      });
      if (field.dataset.name) {
        fieldName = `${field.dataset.name} `;
      }
    }
    if (field.validity.valueMissing) {
      errorMessageElement.textContent = `${fieldName}is missing`;
    } else if (field.validity.typeMismatch || field.validity.patternMismatch) {
      errorMessageElement.textContent = `${fieldName}is not valid`;
    } else if (field.validity.tooShort) {
      errorMessageElement.textContent = `${fieldName}should have at least ${field.minLength} characters`;
    }
  }
};

export const setupFormValidation = () => {
  document.querySelectorAll('form[data-needs-validation]').forEach(form => {
    form.querySelectorAll('input').forEach(field => {
      field.addEventListener('input', () =>
        showFieldValidationError(field, form),
      );
    });
    form.addEventListener('submit', event => {
      const { grecaptcha } = window || {};
      const form = event.target;
      form
        .querySelectorAll('.is-invalid')
        .forEach(element => element.classList.remove('is-invalid'));
      const hasCaptcha =
        recaptchaLoaded && grecaptcha && form.querySelector('.g-recaptcha');
      const isValid = form.checkValidity();
      form.classList.add('was-validated');
      if (isValid) {
        if (hasCaptcha) {
          event.preventDefault();
          event.stopPropagation();
          try {
            grecaptcha.execute();
          } catch (e) {
            console.error(e);
            Rails.disableElement(form);
            form.submit();
          }
        }
      } else {
        form
          .querySelectorAll('input')
          .forEach(field => showFieldValidationError(field, form));
        event.preventDefault();
        event.stopPropagation();
      }
    });
    form.setAttribute('novalidate', '');
  });
};

export const handleRecaptchaSuccess = formId => {
  const form = document.getElementById(formId);
  if (form.nodeName !== 'FORM') return;
  Rails.disableElement(form);
  form.submit();
};

export const handleRecaptchaLoaded = () => {
  recaptchaLoaded = true;
};

export const setupFormAutosubmit = () => {
  const form = document.querySelector('form[data-autosubmit]');
  if (form) {
    form.submit();
  }
};

export const setupSignupSelect = () => {
  const selectEl = document.querySelector('.signup-select');
  if (!selectEl) return;

  const selectedOptionEl = document.querySelector(
    '.signup-select option:checked',
  );
  if (selectedOptionEl.value) {
    selectEl.classList.add('option-selected');
  } else {
    selectEl.classList.add('placeholder-selected');
  }
  selectEl.addEventListener('change', () => {
    selectEl.classList.remove('placeholder-selected');
    selectEl.classList.add('option-selected');
  });
};

/**
 * Simply set the value of the target on a setter
 *
 * meant to be used in an input on the onChange prop as {getOnChangeSetter(mySetter)}
 * @param {import('react').Dispatch<import('react').SetStateAction<any>>} setter
 * @returns {import('react').ChangeEventHandler<HTMLInputElement>}
 */
export const getOnChangeSetter = setter => {
  return e => setter(e.target.value);
};
